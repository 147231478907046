<template>
	<section class="content">
		<!-- <div class="card">
			<div class="card-body"> -->
				<table class="table table-hover" ref="tablesetting">
					<thead>
						<tr>
							<th>Name</th>
							<th>Module</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			<!-- </div>
		</div> -->
		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="showPass">
						<div class="modal-header">
							<h5 class="modal-title">{{ formTitle }}</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
								</ul>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</p>
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Name</label>
									<input v-model="form.name" name="name" id="name" class="form-control" type="text" autocomplete="off" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Module</label>
									<input v-model="form.module" name="module" id="module" class="form-control" type="text" autocomplete="off" />
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Value</label>
									<input v-model="form.value" name="value" id="value" class="form-control" type="text" autocomplete="off" />
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">
								Save changes
							</button>
							<button type="button" class="btn btn-secondary"
								data-dismiss="modal"> Close </button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="formPass" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="back" @click="hidePass" aria-label="Back">
							<span aria-hidden="true">&lt;</span>
						</button>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form role="form" @submit.prevent="checkPass" class="modal-body">
						<div class="form-group">
							<label class="control-label">Password</label>
							<div class="input-group">
								<input id="pwd" class="form-control" v-model="password" value="" type="password" placeholder="Masukkan password" :disabled="loading" />
								<div class="input-group-append">
									<button type="submit" class="btn btn-primary" :disabled="loading">Konfirmasi</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
	name: "DataPengaturan",
	data() {
		return {
			errors: [],
			method: '',
			formTitle: 'Tambah Pengaturan',
			form: {
				name: '',
				module: '',
				value: ''
			},
			loading: false,
			password: '',
			delete: ''
		};
	},
	methods: {
		showPass: function () {
			if (!this.delete) {
				$(this.$refs.formDialog).modal("hide");
			}
			$(this.$refs.formPass).modal("show");
		},
		hidePass: function () {
			$(this.$refs.formPass).modal("hide");
			if (!this.delete) {
				$(this.$refs.formDialog).modal("show");
			}
		},
		checkPass: function () {
			this.loading = true;
			if (this.password == '') {
				Swal.fire("Password harus diisi", ``, "error");
				this.loading = false;
			} else if (this.password.length < 8) {
				Swal.fire("Password tidak valid", ``, "error");
				this.loading = false;
			} else {
				var data = "password=" + encodeURIComponent(this.password);
				var urlSubmit = "/shipper/check_password";

				authFetch(urlSubmit, {
					method: "POST",
					body: data,
				}).then((res) => {
					res.json().then((json) => {
						this.password = '';
						if (json.success) {
							$(this.$refs.formPass).modal("hide");
							if (this.delete) {
								authFetch("/users/setting/" + this.delete, {
									method: "DELETE",
									body: "id=" + this.delete,
								})
								.then((res) => {
									return res.json();
								})
								.then((js) => {
									this.delete = '';
									if (js) {
										this.table.api().ajax.reload(null, false);
									} else {
										Swal.fire("Gagal menghapus pengaturan", ``, "error");
									}
								});
							} else {
								this.submitForm();
							}
						} else {
							Swal.fire({
								title : json.message,
								icon: "error",
							});
						}
						this.loading = false;
					});
				});
			}
		},
		submitForm: function () {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
					(key) =>
						encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");

			authFetch("/users/setting" + (this.method == "PUT" ? "/" + this.form.name : ""), {
				method: this.method,
				body: data,
			})
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				this.errors = [];
				if (!js.success) {
					console.log(js.details);

					for (var key in js.details) {
						if (js.details.hasOwnProperty(key)) {
							this.errors.push(js.details[key]);
						}
					}

					return;
				}
				this.table.api().ajax.reload(null, false);
				$(e.formDialog).modal("hide");
			});
		},
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		this.table = createTable(e.tablesetting, {
			title: "",
			roles: this.$route.params.roles,
			ajax: "/users/setting",
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			columns: [
				{ data: "name" },
				{ data: "module" },
				{ data: "value" }
			],
			filterBy: [ 0, 1, 2 ],
			buttonClick: (evt) => {
				if (evt.role == "create") {
					self.form = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Tambah Pengaturan";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.form = evt.data;
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit Pengaturan";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					console.log(evt.data.name);
					Swal.fire({
						title: "Hapus Pengaturan?",
						icon: "question",
						denyButtonText: '<i class="fa fa-times"></i> Hapus',
						showCancelButton: true,
						showDenyButton: true,
						showConfirmButton: false,
					}).then((result) => {
						if (result.isDenied) {
							this.showPass();
							this.delete = evt.data.name;
						}
					});
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>